body {
  user-select: none;
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

@media (max-width: 600px) {
  body {
    margin: 10px 0;
  }
}

.landing {
  text-align: center;
}

.calendar-modal {
  display: flex;
  flex-direction: column;  
}

.ant-picker-footer {
  display: none;
}


.ant-btn-danger {
  color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  background: #fff !important;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  border-color: #ff4d4f !important;
}

.ant-btn-danger:hover {
  border-color: #ff4d4f !important;
}

.fc-button {
  background-color: royalblue !important;
  border-color: white !important;
}

.fc-button:hover {
  background-color: royalblue !important;
  border-color: white !important;
}

.ant-picker-month-btn {
  pointer-events: none;
}

.ant-picker-year-btn {
  pointer-events: none;
}

.fc-event-title-container {
  cursor: pointer;
}

.ant-picker-time-panel-column {
  padding: 12px !important;
  width: 80px !important;
}

.ant-btn-primary {
  background-color: royalblue !important;
  border-color: royalblue !important;
  color: white !important;
}

.ant-picker-now-btn {
  color: royalblue !important;
}

.ant-picker-today-btn {
  color: royalblue !important;
}

.ant-picker-cell-inner {
  border-color: royalblue !important;
}

.fc-h-event {
  background-color: royalblue !important;
  border-color: royalblue !important;
}